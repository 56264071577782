import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import YouTube from "@u-wave/react-youtube";
import Vimeo from "@u-wave/react-vimeo";
import { videoType } from "../../types";
import { HOSTS_MAP, MIME_TYPE_MAP } from "../../../../shared/constants";
import { getExtension } from "../../../../shared/helpers";

const useStyles = makeStyles(() => ({
  video: {
    width: "100%",
  },
  container: {
    position: "relative",
    "&::before": {
      paddingBottom: "56.25%",
      display: "block",
      content: "''",
    },
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
}));

function Video({
  video: { id, host, source, thumbnail },
  className,
  onEnter,
  onLeave,
  onPlaying,
  onPause,
}) {
  const classes = useStyles();

  if (host === HOSTS_MAP.s3.name) {
    const filename = source.slice(source.lastIndexOf("/") + 1);
    const ext = getExtension(filename);
    const mimeType = MIME_TYPE_MAP[ext];
    return (
      <div
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        onFocus={onEnter}
        onBlur={onLeave}
        onPlay={onPlaying}
        onPause={onPause}
        onPlaying={onPlaying}
        onEnded={onPause}
        onWaiting={onPause}
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          key={id}
          className={`${className} ${classes.video}`}
          controls
          poster={thumbnail ? thumbnail.url : ""}
          controlsList="nodownload"
        >
          <source src={source} type={`video/${mimeType || "*"}`} />
        </video>
      </div>
    );
  }
  return (
    <div
      className={`${className} ${classes.container}`}
      onFocus={onEnter}
      onBlur={onLeave}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      {host === HOSTS_MAP.youtube.name && (
        <YouTube
          video={id}
          className={classes.iframe}
          onPause={onPause}
          onPlaying={onPlaying}
          onEnd={onPause}
          onBuffering={onPause}
          showRelatedVideos={false}
        />
      )}

      {host === HOSTS_MAP.vimeo.name && (
        <Vimeo
          video={id}
          className={classes.iframe}
          onPause={onPause}
          onPlay={onPlaying}
          onEnd={onPause}
          responsive
        />
      )}
    </div>
  );
}

Video.defaultProps = {
  className: "",
};

Video.propTypes = {
  video: videoType.isRequired,
  className: PropTypes.string,
  onEnter: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  onPlaying: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
};

export default Video;
