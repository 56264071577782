import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import PDFPreview from "../PDFPreview";
import Image from "../Image";
import Video from "../Video";
import imageUrlFor from "../../lib/image-url";
import { inferMediaType } from "../../lib/helpers";
import { mediaType } from "../../types";

const useStyles = makeStyles((theme) => ({
  media: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

function MediaViewer({ media, onEnter, onLeave, onPlaying, onPause }) {
  const type = inferMediaType(media);
  const classes = useStyles();

  return (
    <>
      {type === "video" && (
        <Video
          className={classes.media}
          // eslint-disable-next-line no-underscore-dangle
          video={media._rawVideo}
          onEnter={onEnter}
          onLeave={onLeave}
          onPlaying={onPlaying}
          onPause={onPause}
        />
      )}
      {type === "file" && <PDFPreview className={classes.media} />}
      {type === "image" && (
        <Image
          onEnter={onEnter}
          onLeave={onLeave}
          width={1200}
          src={imageUrlFor(media.image).width(1200).url()}
          alt={media.image.alt}
          className={classes.media}
        />
      )}
    </>
  );
}

MediaViewer.defaultProps = {
  media: null,
  onEnter: () => {},
  onLeave: () => {},
  onPlaying: () => {},
  onPause: () => {},
};

MediaViewer.propTypes = {
  media: mediaType,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  onPlaying: PropTypes.func,
  onPause: PropTypes.func,
};

export default MediaViewer;
