import React from "react";
import PropTypes from "prop-types";
import FileIcon from "@material-ui/icons/Description";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "320px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up("sm")]: {
      height: "480px",
    },
  },
  icon: {
    fontSize: theme.spacing(12),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.spacing(16),
    },
  },
}));

const PDFPreview = ({ handleNotHover, handleIsHover, className }) => {
  const classes = useStyles();
  return (
    <div
      className={`${className} ${classes.root}`}
      onMouseEnter={handleIsHover}
      onMouseLeave={handleNotHover}
    >
      <FileIcon className={classes.icon} />
    </div>
  );
};

PDFPreview.defaultProps = {
  handleIsHover: () => {},
  handleNotHover: () => {},
  className: "",
};

PDFPreview.propTypes = {
  handleIsHover: PropTypes.func,
  handleNotHover: PropTypes.func,
  className: PropTypes.string,
};

export default PDFPreview;
