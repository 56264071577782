import { inferMediaType } from "../lib/helpers";

function useDownloadMedia(media) {
  const getDownloadUrl = (m, type) => {
    if (type === "file") {
      return m.file.asset.url;
    }

    if (type === "image") {
      return m.image.asset.url;
    }

    // eslint-disable-next-line no-underscore-dangle
    return m._rawVideo && m._rawVideo.source ? m._rawVideo.source : "";
  };

  const type = inferMediaType(media);
  const url = getDownloadUrl(media, type);

  const isDownloadable =
    url &&
    (type === "image" ||
      type === "file" ||
      // eslint-disable-next-line no-underscore-dangle
      (media._rawVideo.host !== "vimeo" && media._rawVideo.host !== "youtube"));

  return { isDownloadable, url };
}

export default useDownloadMedia;
