export const HOSTS_MAP = {
  s3: { title: "S3", name: "s3" },
  youtube: { title: "Youtube", name: "youtube" },
  vimeo: { title: "Vimeo", name: "vimeo" },
};

export const MIME_TYPE_MAP = {
  mp4: "mp4",
  flv: "x-flv",
  mov: "quicktime",
  png: "png",
};
